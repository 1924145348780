import { createStore } from "vuex";

export default createStore({
  state: {
    token: null,
    user: null,
    organisation: null,
    messagesLeft: 0,
    logo: null,
    selectedLanguages: [],
    questionList: [],
    surveyLanguages: [], 
  },
  getters: {

    getSurveyLanguages(state) { 
      return state.surveyLanguages; 
    },  
    getQuestionList(state) {
      return state.questionList;
    },
    getSelectedLanguages(state) {
      return state.selectedLanguages;
    },
    getUser(state) {
      return state.user;
    },
    getOrganisation(state) {
      return state.organisation;
    },
    getMessagesLeft(state) {
      return state.messagesLeft;
    },
    getLogo(state) {
      return state.logo;
    },
  },
  mutations: {
    SET_SURVEY_LANGUAGES(state, payload) {
      state.surveyLanguages = payload;
    }, 
    SET_QUESTION_LIST(state, payload) {
      state.questionList = payload;
    },
    SET_SELECTED_LANGUAGES(state, payload) {
      state.selectedLanguages = payload;
    },
    REDUCE_MESSAGES_LEFT(state, payload) {
      state.messagesLeft -= payload;
    },
    SET_MESSAGES_LEFT(state, payload) {
      state.messagesLeft = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_USER(state, payload) {
      state.user = {
        id: payload.id,
        email: payload.email,
        userName: payload.userName,
      };
    },
    LOGOUT_USER(state, payload) {
      state.user = null;
      state.token = null;
    },
    SET_ORGANISATION(state, payload) {
      state.organisation = payload;
    },
    SET_LOGO(state, payload) {
      state.logo = payload;
    },
  },
  actions: {
    setQuestionList({ commit }, payload) {
      commit("SET_QUESTION_LIST", payload);
    },
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
    },
    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    logoutUser({ commit }) {
      commit("LOGOUT_USER");
    },
    setOrganisation({ commit }, payload) {
      commit("SET_ORGANISATION", payload);
    },
    reduceMsgLeft({ commit }, payload) {
      commit("REDUCE_MESSAGES_LEFT", payload);
    },
    setMsgLeft({ commit }, payload) {
      commit("SET_MESSAGES_LEFT", payload);
    },
    setLogo({ commit }, payload) {
      commit("SET_LOGO", payload);
    },
    setSelectedLanguages({ commit }, payload) {
      commit("SET_SELECTED_LANGUAGES", payload);
    },
    setSurveyLanguages({commit}, payload) {
      commit("SET_SURVEY_LANGUAGES", payload);
    }
  },

  modules: {},
});
